// @import "~bootstrap/scss/bootstrap";
@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import 'libs/html-grid';
@import url("fontawesome-all.min.css");
// @import url("https://fonts.googleapis.com/css?family=Lato:300,400,900");
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700;900&display=swap');

/*
	Twenty by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

$pattern-background-size: 25em;
// Breakpoints.
@include breakpoints(( wide: ( 1281px, 1680px),
normal: ( 981px, 1280px),
narrow: ( 841px, 980px),
narrower: ( 737px, 840px),
mobile: ( null, 736px)));
// Reset.
// Based on meyerweb.com/eric/tools/css/reset (v2.0 | 20110126 | License: public domain)
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
    &:before,
    &:after {
        content: '';
        content: none;
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    -webkit-text-size-adjust: none;
}

mark {
    background-color: transparent;
    color: inherit;
}

input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input,
select,
textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
}


/* Basic */

// Set box model to border-box.
// Based on css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    background: _palette(bg);
    // Stops initial animations until page loads.
    &.is-preload {
        *,
        *:before,
        *:after {
            @include vendor('animation',
            'none !important');
            @include vendor('transition',
            'none !important');
        }
    }
}

body,
input,
select,
textarea {
    color: _palette(fg);
    //  font-family: 'Lato', sans-serif;
    font-family: 'Raleway', sans-serif;
    font-size: 15pt;
    font-weight: 300;
    letter-spacing: 0.025em;
    line-height: 1.75em;
}

a {
    @include vendor('transition',
    ('color 0.2s ease-in-out',
    'border-color 0.2s ease-in-out',
    'background-color 0.2s ease-in-out'));
    color: darken(_palette(one, bg), 20);
    text-decoration: none;
    border-bottom: dotted 1px;
    &:hover {
        border-bottom-color: transparent;
    }
}

strong,
b {
    font-weight: 700;
}

p,
ul,
ol,
dl,
table,
blockquote {
    margin: 0 0 2em 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: inherit;
    font-weight: 300;
    line-height: 1.75em;
    margin-bottom: 1em;
    text-transform: uppercase;
    a {
        color: inherit;
        text-decoration: none;
        border: 0;
    }
}

h2 {
    font-size: 1.5em;
    letter-spacing: 0.1em;
}

h3 {
    font-size: 1.15em;
    letter-spacing: 0.025em;
}

sub {
    font-size: 0.8em;
    position: relative;
    top: 0.5em;
}

sup {
    font-size: 0.8em;
    position: relative;
    top: -0.5em;
}

hr {
    border-top: solid 1px _palette(border);
    border: 0;
    margin-bottom: 1.5em;
}

blockquote {
    border-left: solid 0.5em _palette(border);
    font-style: italic;
    padding: 1em 0 1em 2em;
}


/* Container */

.container {
    margin: 0 auto;
    max-width: 100%;
    width: 1400px;
    &.medium {
        width: (1400px * 0.75);
    }
    @include breakpoint('<=wide') {
        width: 1200px;
        &.medium {
            width: (1200px * 0.75);
        }
    }
    @include breakpoint('<=normal') {
        width: 960px;
        &.medium {
            width: (980px * 0.75);
        }
    }
    @include breakpoint('<=narrow') {
        width: 95%;
        &.medium {
            width: (95% * 0.75);
        }
    }
    @include breakpoint('<=narrower') {
        width: 95%;
        &.medium {
            width: 95%;
        }
    }
    @include breakpoint('<=mobile') {
        width: 100%;
        &.medium {
            width: 100%;
        }
    }
}


/* Row */

.row {
    @include html-grid((50px,
    50px));
    @include breakpoint('<=wide') {
        @include html-grid((40px,
        40px),
        'wide');
    }
    @include breakpoint('<=normal') {
        @include html-grid((40px,
        40px),
        'normal');
    }
    @include breakpoint('<=narrow') {
        @include html-grid((30px,
        30px),
        'narrow');
    }
    @include breakpoint('<=narrower') {
        @include html-grid((30px,
        30px),
        'narrower');
    }
    @include breakpoint('<=mobile') {
        @include html-grid((30px,
        30px),
        'mobile');
    }
}


/* Section/Article */

section,
article {
    &.special {
        text-align: center;
    }
}

header {
    &.major {
        padding-bottom: 2em;
    }
    &.special {
        margin-bottom: 5em;
        padding-top: 7em;
        position: relative;
        text-align: center;
        &:before,
        &:after {
            border-bottom: solid 1.5px;
            border-top: solid 1.5px;
            content: '';
            height: 7px;
            opacity: 0.1;
            position: absolute;
            top: 1.75em;
            width: 43%;
        }
        &:before {
            left: 0;
        }
        &:after {
            right: 0;
        }
        h2 {
            margin-bottom: 0;
        }
        h2+p {
            margin-bottom: 0;
            padding-top: 1.5em;
        }
        .icon {
            cursor: default;
            height: 7em;
            left: 0;
            position: absolute;
            text-align: center;
            top: 1em;
            width: 100%;
            &:before {
                font-size: 3.5em;
                opacity: 0.35;
            }
        }
    }
}

footer {
    > :last-child {
        margin-bottom: 0;
    }
    &.major {
        padding-top: 3em;
    }
}


/* Form */

input[type="text"],
input[type="password"],
input[type="email"],
textarea {
    @include vendor('transition',
    ('background-color 0.2s ease-in-out',
    'border-color 0.2s ease-in-out'));
    @include vendor('appearance',
    'none');
    background: none;
    border: solid 1px _palette(border);
    border-radius: 0;
    color: inherit;
    display: block;
    padding: 0.75em;
    text-decoration: none;
    width: 100%;
    outline: 0;
    &:focus {
        border-color: _palette(one, bg);
    }
}

input[type="text"],
input[type="password"],
input[type="email"] {
    line-height: 1em;
}

::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.5;
    position: relative;
    top: 3px;
}

:-moz-placeholder {
    color: inherit;
    opacity: 0.5;
}

::-moz-placeholder {
    color: inherit;
    opacity: 0.5;
}

:-ms-input-placeholder {
    color: inherit;
    opacity: 0.5;
}


/* Image */

.image {
    border: 0;
    position: relative;
    &:before {
        //   background: url('../../images/overlay.png');
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
    &.fit {
        display: block;
        img {
            display: block;
            width: 100%;
        }
    }
    &.featured {
        display: block;
        margin: 0 0 2em 0;
        img {
            display: block;
            width: 100%;
        }
    }
}


/* Icon */

.icon {
    @include icon;
    position: relative;
    &.solid {
        &:before {
            font-weight: 900;
        }
    }
    &:before {
        line-height: inherit;
    }
    &.brands {
        &:before {
            font-family: 'Font Awesome 5 Brands';
        }
    }
    &.circle {
        @include vendor('transition',
        'all 0.2s ease-in-out');
        border: 0;
        border-radius: 100%;
        display: inline-block;
        font-size: 1.25em;
        height: 2.25em;
        left: 0;
        line-height: 2.25em;
        text-align: center;
        text-decoration: none;
        top: 0;
        width: 2.25em;
        &:hover {
            top: -0.2em;
        }
        &.fa-twitter {
            background: _palette(twitter, bg);
            color: _palette(twitter, fg);
            &:hover {
                background: lighten(_palette(twitter, bg), 4);
            }
        }
        &.fa-facebook-f {
            background: _palette(facebook, bg);
            color: _palette(facebook, fg);
            &:hover {
                background: lighten(_palette(facebook, bg), 4);
            }
        }
        &.fa-google-plus-g {
            background: _palette(google-plus, bg);
            color: _palette(google-plus, fg);
            &:hover {
                background: lighten(_palette(google-plus, bg), 4);
            }
        }
        &.fa-github {
            background: _palette(github, bg);
            color: _palette(github, fg);
            &:hover {
                background: lighten(_palette(github, bg), 4);
            }
        }
        &.fa-dribbble {
            background: _palette(dribbble, bg);
            color: _palette(dribbble, fg);
            &:hover {
                background: lighten(_palette(dribbble, bg), 4);
            }
        }
    }
    &.featured {
        cursor: default;
        display: block;
        margin: 0 0 1.5em 0;
        opacity: 0.35;
        text-align: center;
        &:before {
            font-size: 5em;
            line-height: 1em;
        }
    }
    >.label {
        display: none;
    }
}


/* List */

ol {
    list-style: decimal;
    padding-left: 1.25em;
    li {
        padding-left: 0.25em;
    }
}

ul {
    list-style: disc;
    padding-left: 1em;
    li {
        padding-left: 0.5em;
    }
}


/* Icons */

ul.icons {
    list-style: none;
    padding-left: 0;
    cursor: default;
    li {
        display: inline-block;
        line-height: 1em;
        padding-left: 0.5em;
        &:first-child {
            padding-left: 0;
        }
    }
}


/* Featured Icons */

ul.featured-icons {
    cursor: default;
    margin: -0.75em 0 0 0;
    opacity: 0.35;
    overflow: hidden;
    position: relative;
    list-style: none;
    padding-left: 0;
    li {
        display: block;
        float: left;
        text-align: center;
        width: 50%;
        padding-left: 0;
        margin-bottom: 1em;
        .icon {
            display: inline-block;
            font-size: 5.5em;
            height: 1em;
            line-height: 1.5em;
            width: 1em;
        }
    }
}


/* Buttons */

ul.buttons {
    cursor: default;
    list-style: none;
    padding-left: 0;
    &:last-child {
        margin-bottom: 0;
    }
    li {
        display: inline-block;
        padding: 0 0 0 1.5em;
        &:first-child {
            padding: 0;
        }
    }
    &.stacked {
        li {
            display: block;
            padding: 1.5em 0 0 0;
            &:first-child {
                padding: 0;
            }
        }
    }
}


/* Table */

table {
    width: 100%;
    &.default {
        width: 100%;
        tbody {
            tr {
                border-bottom: solid 1px _palette(border);
            }
        }
        td {
            padding: 0.5em 1em 0.5em 1em;
        }
        th {
            font-weight: 400;
            padding: 0.5em 1em 0.5em 1em;
            text-align: left;
        }
        thead {
            background: _palette(fg);
            color: #fff;
        }
        tfoot {}
        tbody {}
    }
}


/* Button */

input[type="button"],
input[type="submit"],
input[type="reset"],
button,
.button {
    @include vendor('appearance',
    'none');
    @include vendor('transition',
    ('color 0.2s ease-in-out',
    'border-color 0.2s ease-in-out',
    'background-color 0.2s ease-in-out'));
    background: none;
    border: solid 1px;
    border-radius: 0;
    color: inherit;
    cursor: pointer;
    display: inline-block;
    font-size: 0.8em;
    font-weight: 900;
    letter-spacing: 2px;
    min-width: 18em;
    padding: 0 0.75em;
    line-height: 3.75em;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    &:hover {
        background: rgba(188, 202, 206, 0.15);
        border-color: inherit;
    }
    &.primary {
        background: _palette(one, bg);
        border-color: _palette(one, bg);
        color: _palette(one, fg) !important;
        &:hover {
            background: lighten(_palette(one, bg), 5) !important;
            border-color: lighten(_palette(one, bg), 5) !important;
        }
    }
    &.fit {
        width: 100%;
    }
    &.small {
        font-size: 0.7em;
        min-width: 14em;
        padding: 0.5em 0;
    }
}


/* Wrapper */

.wrapper {
    margin-bottom: 5em;
    padding: 5em;
    &.style1 {
        padding: 0;
    }
    &.style2 {
        background-color: _palette(one, bg);
        //   background-image: url('images/light-bl.svg'), url('images/light-br.svg');
        background-position: bottom left, bottom right;
        background-repeat: no-repeat, no-repeat;
        background-size: $pattern-background-size, $pattern-background-size;
        color: _palette(one, fg);
        input[type="button"],
        input[type="submit"],
        input[type="reset"],
        button,
        .button {
            &:hover {
                background: rgba(255, 255, 255, 0.15) !important;
            }
            &.primary {
                background: _palette(one, fg);
                border-color: _palette(one, fg);
                color: _palette(one, bg) !important;
                &:hover {
                    border-color: inherit !important;
                    color: _palette(one, fg) !important;
                }
            }
        }
    }
    &.style3 {
        background: _palette(two, bg);
        color: _palette(two, fg);
    }
    &.style4 {
        background: _palette(two, bg);
        color: _palette(two, fg);
        padding: 4em;
    }
}


/* Header */

@include keyframes('reveal-header') {
    0% {
        top: -5em;
    }
    100% {
        top: 0;
    }
}

#header {
    background: _palette(header, bg);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.075);
    color: _palette(header, fg);
    cursor: default;
    font-size: 0.8em;
    left: 0;
    padding: 1em 1.5em;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
    h1 {
        font-weight: 900;
        margin: 0;
        span {
            font-weight: 300;
        }
    }
    nav {
        letter-spacing: 0.075em;
        position: absolute;
        right: 1.5em;
        text-transform: uppercase;
        top: 0.75em;
        ul {
            list-style: none;
            padding-left: 0;
            margin-top: 1em;
            li {
                display: inline-block;
                margin-left: 1.5em;
                padding-left: 0;
                @include breakpoint('<=narrow') {
                    margin-left: 0 !important;
                }
                >ul {
                    display: none;
                }
                a {
                    border: solid 1px transparent;
                    color: inherit;
                    display: inline-block;
                    line-height: 1em;
                    padding: 0.6em 0.75em;
                    text-decoration: none;
                }
                input[type="button"],
                input[type="submit"],
                input[type="reset"],
                button,
                .button {
                    font-size: 1em;
                    min-width: 0;
                    width: auto;
                }
                &.submenu {
                    >a {
                        @include icon(false,
                        solid);
                        &:before {
                            content: '\f107';
                            margin-right: 0.65em;
                        }
                    }
                }
                &.active,
                &:hover {
                    >a {
                        @include vendor('transition',
                        'all 0.2s ease-in-out');
                        background: rgba(188, 202, 206, 0.15);
                    }
                }
                &.current {
                    >a {
                        font-weight: 900;
                    }
                }
            }
        }
    }
    img.logo-img {
        width: 240px;
    }
    @include breakpoint('<=narrow') {
        img.logo-img {
            width: 200px;
        }
    }
    &.reveal {
        @include vendor('animation',
        'reveal-header 0.5s');
    }
    &.alt {
        @include vendor('animation',
        'none');
        background: _palette(header, alt-bg);
        box-shadow: none;
        color: _palette(header, alt-fg);
        padding: 2em 2.5em;
        position: absolute;
        nav {
            right: 2.5em;
            top: 1.75em;
            ul {
                li {
                    &.active,
                    &:hover {
                        >a {
                            border: solid 1px;
                        }
                    }
                }
            }
        }
        img.logo-img {
            display: none;
        }
    }
}


/* Dropotron */

.dropotron {
    list-style: none;
    padding-left: 0;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.075);
    line-height: 2.25em;
    min-width: 13em;
    padding: 1em 0;
    text-transform: uppercase;
    margin-top: calc(-1em + 1px);
    &.level-0 {
        font-size: 0.7em;
        font-weight: 400;
        margin-top: 1.5em;
        &:before {
            border-bottom: solid 0.5em #fff;
            border-left: solid 0.5em transparent;
            border-right: solid 0.5em transparent;
            content: '';
            left: 0.75em;
            position: absolute;
            top: -0.45em;
        }
    }
    >li {
        border-top: solid 1px _palette(border);
        padding-left: 0;
        >a {
            @include vendor('transition',
            'none');
            color: inherit;
            text-decoration: none;
            padding: 0 1em;
            border: 0;
        }
        &:hover {
            >a {
                background: _palette(one, bg);
                color: _palette(one, fg);
            }
        }
        &:first-child {
            border-top: 0;
        }
    }
}


/* Banner */

@include keyframes('reveal-banner') {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

#banner {
    background-attachment: scroll, scroll, scroll, fixed;
    background-color: _palette(three, bg);
    background-image: url('/public/img/banner.jpg');
    background-position: top center;
    background-repeat: no-repeat;
    @media (min-width: 1480px) {
        background-size: 100%;
    }
    background-size: cover;
    color: _palette(banner,
    fg);
    cursor: default;
    padding: 6em 0;
    text-align: center;
    position: relative;
    .overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #8f26265b;
        //   background-color: transparentize(_palette(three, bg), 0.25);
    }
    .inner {
        @include vendor('animation',
        'reveal-banner 1s 0.25s ease-in-out');
        @include vendor('animation-fill-mode',
        'forwards');
        background: transparentize(_palette(banner, inner-bg), 0.25);
        color: _palette(banner, inner-fg);
        display: inline-block;
        opacity: 0;
        padding: 3em;
        text-align: center;
        header {
            display: inline-block;
            border-bottom: solid 2px;
            border-top: solid 2px;
            margin: 0 0 2em 0;
            padding: 3px 0 3px 0;
            h2 {
                border-bottom: solid 2px;
                border-top: solid 2px;
                font-size: 2.5em;
                font-weight: 900;
                letter-spacing: 0.2em;
                margin: 0;
                padding-left: 0.05em;
                position: relative;
                text-transform: uppercase;
            }
        }
        p {
            letter-spacing: 0.1em;
            margin: 0;
            text-transform: uppercase;
            a {
                color: inherit;
                font-weight: 400;
                text-decoration: none;
            }
        }
        footer {
            margin: 2em 0 0 0;
        }
    }
}


/* Main */

#main {
    //  background-image: url('images/dark-tl.svg'), url('images/dark-tr.svg'), url('images/dark-bl.svg'), url('images/dark-br.svg');
    background-position: top left, top right, bottom left, bottom right;
    background-repeat: no-repeat;
    background-size: $pattern-background-size;
    padding: 7em 0;
    > :last-child {
        margin-bottom: 0;
    }
    .sidebar {
        section {
            border-top: solid 1px _palette(border);
            margin: 3em 0 0 0;
            padding: 3em 0 0 0;
            &:first-child {
                border-top: 0;
                padding-top: 0;
                margin-top: 0;
            }
        }
    }
}

body.index #main {
    padding-top: 5em;
}


/* CTA */

#cta {
    background-attachment: scroll, scroll, scroll, fixed;
    background-color: _palette(three, bg);
    //  background-image: url('images/light-tl.svg'), url('images/light-tr.svg'), url('images/overlay.png'), url('../../images/banner.jpg');
    background-position: top left, top right, top left, bottom center;
    background-repeat: no-repeat, no-repeat, repeat, no-repeat;
    background-size: $pattern-background-size, $pattern-background-size, auto, cover;
    color: _palette(three, fg);
    padding: 5em;
    text-align: center;
    header {
        margin-bottom: 2em;
    }
}


/* Footer */

#footer {
    background: _palette(footer, bg);
    color: _palette(footer, fg);
    padding: 5em 5em 10em 5em;
    text-align: center;
    .copyright {
        font-size: 0.8em;
        line-height: 1em;
        padding: 0;
        a {
            color: inherit;
        }
        li {
            display: inline-block;
            margin-left: 1em;
            padding-left: 1em;
            border-left: dotted 1px;
            &:first-child {
                margin: 0;
                padding: 0;
                border: 0;
            }
        }
    }
}


/* Wide */

@include breakpoint('<=wide') {
    /* Basic */
    body,
    input,
    select,
    textarea {
        font-size: 14pt;
    }
    /* Section/Article */
    header {
        &.special {
            padding-top: 5.5em;
            margin-bottom: 4em;
        }
    }
}


/* Normal */

@include breakpoint('<=normal') {
    /* Basic */
    body,
    input,
    select,
    textarea {
        font-size: 13pt;
        letter-spacing: 0.025em;
        line-height: 1.65em;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.5em;
    }
    /* Section/Article */
    header {
        &.major {
            padding-bottom: 1.5em;
        }
    }
    footer {
        &.major {
            padding-top: 2em;
        }
    }
    /* Wrapper */
    .wrapper {
        margin-bottom: 4em;
        padding: 4em 3em;
        &.style4 {
            padding: 3em;
        }
    }
    /* Header */
    #header {
        nav {
            ul {
                li {
                    margin-left: 1em;
                }
            }
        }
    }
    /* Banner */
    #banner {
        background-attachment: scroll;
    }
    /* CTA */
    #cta {
        padding: 4em;
        background-attachment: scroll;
    }
    /* Footer */
    #footer {
        padding: 4em;
    }
}


/* Narrow */

@include breakpoint('<=narrow') {
    $pattern-background-size: 15em;
    /* Basic */
    body,
    input,
    select,
    textarea {
        font-size: 13pt;
        letter-spacing: 0.025em;
        line-height: 1.5em;
    }
    /* Section/Article */
    header {
        //   br {
        //       // display: none;
        //   }
        &.major {
            padding-bottom: 1em;
        }
        &.special {
            padding-left: 2.5em;
            padding-right: 2.5em;
        }
    }
    footer {
        &.major {
            padding-top: 1.5em;
        }
    }
    /* Wrapper */
    .wrapper {
        margin-bottom: 3em;
        padding: 3em 2.5em;
        &.special {
            br {
                //  display: none;
            }
        }
        &.style1 {
            padding: 0 2.5em;
        }
        &.style2 {
            background-size: $pattern-background-size;
        }
        &.style4 {
            padding: 2.5em;
        }
    }
    /* Banner */
    #banner {
        //   background-size: $pattern-background-size, $pattern-background-size, auto, cover;
    }
    /* Main */
    #main {
        background-size: $pattern-background-size;
    }
    /* CTA */
    #cta {
        background-size: $pattern-background-size, $pattern-background-size, auto, cover;
        padding: 3em;
    }
}


/* Narrower */

#navPanel,
#navButton {
    display: none;
}

@include breakpoint('<=narrower') {
    /* Basic */
    html,
    body {
        overflow-x: hidden;
    }
    header {
        &.major {
            padding-bottom: 0.25em;
        }
        &.special {
            margin-bottom: 4em;
            padding-top: 5em;
            &:before,
            &:after {
                width: 40%;
            }
            h2+p {
                padding-top: 1.25em;
            }
        }
    }
    /* Section/Article */
    section {
        margin: 1em 0 1em 0;
        &:first-child {
            margin-top: 0;
        }
    }
    /* Button */
    input[type="button"],
    input[type="submit"],
    input[type="reset"],
    button,
    .button {
        &.small {
            font-size: 0.8em;
            min-width: 18em;
            padding: 0.75em 0;
        }
    }
    /* Featured Icons */
    ul.featured-icons {
        margin: 0;
        li {
            display: inline-block;
            float: none;
            width: auto;
            .icon {
                font-size: 4em;
                width: 1.25em;
            }
        }
    }
    /* Buttons */
    ul.buttons {
        li {
            display: block;
            padding: 1em 0 0 0;
        }
    }
    /* Header */
    #header {
        display: none;
    }
    /* Banner */
    #banner {
        margin: 0;
    }
    /* Wrapper */
    .wrapper {
        &.special-alt {
            text-align: center;
        }
        &.style4 {
            padding-bottom: 3em;
        }
    }
    /* Main */
    #main {
        padding: 5em 0;
        .sidebar {
            border-top: solid 1px transparentize(_palette(fg), 0.9);
            padding-top: 3em;
            section {
                border-top: 0;
                padding-top: 0;
            }
        }
    }
    body.index #main {
        padding-top: 4.5em;
    }
    /* CTA */
    #cta {
        margin: 0;
    }
    /* Footer */
    #footer {
        padding: 4em 1.5em;
    }
    /* Nav */
    #page-wrapper {
        @include vendor('backface-visibility',
        'hidden');
        @include vendor('transition',
        'transform #{_duration(navPanel)} ease');
        padding-bottom: 1px;
    }
    #navButton {
        @include vendor('backface-visibility',
        'hidden');
        @include vendor('transition',
        'transform #{_duration(navPanel)} ease');
        display: block;
        height: 60px;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: _misc(z-index-base) + 1;
        .toggle {
            @include icon(false,
            solid);
            height: 60px;
            left: 0;
            position: absolute;
            text-align: center;
            top: 0;
            width: 100%;
            border: 0;
            outline: 0;
            &:before {
                color: _palette(navButton, fg);
                content: '\f0c9';
                font-size: 1em;
                height: 40px;
                left: 10px;
                line-height: 40px;
                opacity: 0.5;
                position: absolute;
                top: 11px;
                width: 60px;
                z-index: 1;
            }
            &:after {
                background: _palette(navButton, bg);
                border-radius: 2px;
                content: '';
                height: 40px;
                left: 10px;
                position: absolute;
                top: 10px;
                width: 60px;
            }
        }
    }
    #navPanel {
        @include vendor('backface-visibility',
        'hidden');
        @include vendor('transform',
        'translateX(#{_size(navPanel) * -1})');
        @include vendor('transition',
        ('transform #{_duration(navPanel)} ease'));
        display: block;
        height: 100%;
        left: 0;
        overflow-y: auto;
        position: fixed;
        top: 0;
        width: _size(navPanel);
        z-index: _misc(z-index-base) + 2;
        background: _palette(navPanel, bg);
        color: _palette(navPanel, fg);
        font-size: 0.8em;
        letter-spacing: 0.075em;
        text-transform: uppercase;
        padding: 0.25em 0.75em 1em 0.75em;
        .link {
            border: 0;
            border-top: solid 1px transparentize(_palette(navPanel, fg), 0.95);
            color: inherit;
            display: block;
            height: 3em;
            line-height: 3em;
            opacity: 0.75;
            text-decoration: none;
            &.depth-0 {
                font-weight: 900;
            }
            &:first-child {
                border-top: 0;
            }
        }
        .indent-1 {
            display: inline-block;
            width: 1em;
        }
        .indent-2 {
            display: inline-block;
            width: 2em;
        }
        .indent-3 {
            display: inline-block;
            width: 3em;
        }
        .indent-4 {
            display: inline-block;
            width: 4em;
        }
        .indent-5 {
            display: inline-block;
            width: 5em;
        }
    }
    body {
        &.navPanel-visible {
            #page-wrapper {
                @include vendor('transform',
                'translateX(#{_size(navPanel)})');
            }
            #navButton {
                @include vendor('transform',
                'translateX(#{_size(navPanel)})');
            }
            #navPanel {
                @include vendor('transform',
                'translateX(0)');
            }
        }
    }
}


/* Mobile */

@include breakpoint('<=mobile') {
    $pattern-background-size: 10em;
    /* Basic */
    //  body {
    //      min-width: 320px;
    //  }
    h2 {
        font-size: 1.25em;
        letter-spacing: 0.1em;
    }
    h3 {
        font-size: 1em;
        letter-spacing: 0.025em;
    }
    p {
        //   text-align: justify;
    }
    /* Section/Article */
    header {
        text-align: center;
        &.major {
            padding-bottom: 0;
        }
        &.special {
            margin-bottom: 3em;
            padding-left: 1.5em;
            padding-right: 1.5em;
            &:before,
            &:after {
                width: 38%;
            }
            .icon {
                font-size: 0.75em;
                top: 1.5em;
            }
        }
        p {
            text-align: center;
        }
    }
    footer {
        &.major {
            padding-top: 0;
        }
    }
    /* Icon */
    .icon {
        &.circle {
            font-size: 1em;
        }
    }
    /* Button */
    input[type="button"],
    input[type="submit"],
    input[type="reset"],
    button,
    .button {
        max-width: 20em;
        width: 100%;
        &.fit {
            width: auto;
        }
    }
    /* Icons */
    ul.icons {
        li {
            padding-left: 0.25em;
        }
    }
    /* Featured Icons */
    ul.featured-icons {
        li {
            .icon {
                width: 1.1em;
            }
        }
    }
    /* Buttons */
    ul.buttons {
        text-align: center;
    }
    /* Wrapper */
    .wrapper {
        margin-bottom: 2.5em;
        padding: 2.25em 1.5em;
        //   &.special {
        // br {
        //     display: none;
        // }
        //   }
        &.style1 {
            padding: 0 1.5em;
        }
        &.style2 {
            background-size: $pattern-background-size;
            padding: 2.25em 1.5em;
        }
        &.style4 {
            background-size: $pattern-background-size;
            padding: 1.5em 1.5em 3em 1.5em;
        }
    }
    /* Banner */
    #banner {
        //   background-size: $pattern-background-size, $pattern-background-size, auto, cover;
        padding: 6em 0;
        .inner {
            // background: none;
            // display: block;
            padding: 1.5em;
            img {
                max-width: 100%;
                display: block;
                height: auto;
                margin: 0 auto;
            }
            header {
                h2 {
                    font-size: 1.5em;
                }
            }
            p {
                text-align: center;
            }
            br {
                display: none;
            }
        }
    }
    /* Main */
    #main {
        background-size: $pattern-background-size;
        padding: 3.5em 0 2.5em 0;
    }
    body.index #main {
        padding: 2.5em 0 0 0;
    }
    body.contact #main {
        padding-bottom: 0;
    }
    /* CTA */
    #cta {
        background-size: $pattern-background-size, $pattern-background-size, auto, cover;
        padding: 3em 1.5em;
    }
    /* Footer */
    #footer {
        padding: 3em 1.5em;
        .copyright {
            li {
                display: block;
                margin: 1em 0 0 0;
                padding: 0;
                border: 0;
            }
        }
    }
    /* Nav */
    #navButton {
        .toggle {
            &:before {
                top: 8px;
                left: 8px;
                width: 50px;
                height: 34px;
                line-height: 34px;
            }
            &:after {
                top: 8px;
                left: 8px;
                width: 50px;
                height: 34px;
            }
        }
    }
}

.alert {
    margin-bottom: 3em;
    &.alert-success {
        background: _palette(one, bg);
        font-weight: 700;
    }
}

.contact-map {
    margin-bottom: 1em;
    iframe {
        max-width: 100%;
    }
}

.border-none {
    border: none;
}

.img-responsive {
    max-width: 100%;
    height: auto;
    display: block;
}

.display-only-narrower {
    display: none !important;
}

@include breakpoint('<=narrower') {
    .display-only-narrower {
        display: block;
    }
}

.cta-logo {
    border: none;
    img {
        max-width: 500px;
    }
}

.cta-p {
    margin-top: 3em;
}

.mt-30 {
    margin-top: 3rem;
}

.mt-15 {
    margin-top: 1.5rem;
}

.mx-auto{
    margin-left: auto;
    margin-right: auto;
}